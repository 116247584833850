






import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
@Component
export default class EventDetail extends Vue {
  eventDetail: object = {};

  GetById () {
    this.$Api.calendar.GetById({ id: this.eventId, IsMobile: this.isMobile }).then(result => {
      if (result.Succeeded) {
        console.log(result, '活动内容');
        this.eventDetail = result.ReturnValue;
      }
    });
  }

  back () {
    this.$router.go(-1);
  }

  get eventId () {
    return this.$route.params.id;
  }

  get isMobile () {
    return this.$store.state.isMobile;
  }

  mounted () {
    this.GetById();
  }
}
